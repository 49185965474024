import React, { useCallback } from "react"
import {FormattedMessage} from "react-intl" 
import {Card, Button, Image} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { getDefaultNames } from "../../utilities/utils"
import {getCreditCardIcon} from "../../utilities/creditCardsIcon"
import currencySymbols from "../../utilities/currencySymbols"
import { doPayment } from "./paymentSlice";

const PaymentResponse = () => {
    const dispatch = useDispatch();
    const paymentStatus     = useSelector((state) => state.payment.status)
    const paymentDate       = useSelector((state) => state.payment.date)
    const paymentCard       = useSelector((state) => state.payment.card)
    const paymentAmount     = useSelector((state) => state.payment.amount);
    const paymentCurrency   = useSelector((state) => state.payment.currency);
    const user              = useSelector(state => state.payment.user);

    const getCreditCardIconElt = useCallback(({...rest}) => {
        if(!paymentCard?.brand) return null;
        return getCreditCardIcon(paymentCard.brand, rest)
    }, [paymentCard])

    const getStatus = useCallback(() => {
        return {
            "success": <FormattedMessage id="paymentResponse.status.success" defaultMessage="Success" />,
            "too-many-failed-attempts": <FormattedMessage id="paymentResponse.status.too-many-failed-attempts" defaultMessage="Too many failed attempts" />,
            "payment-connector-error": <FormattedMessage id="paymentResponse.status.payment-connector-error" defaultMessage="Payment connector error" />,
            "caller-interrupted-with-star": <FormattedMessage id="paymentResponse.status.caller-interrupted-with-star" defaultMessage="Caller interrupted with star" />,
            "caller-hung-up": <FormattedMessage id="paymentResponse.status.caller-hung-up" defaultMessage="Caller hung-up" />,
            "internal-error": <FormattedMessage id="paymentResponse.status.internal-error" defaultMessage="Internal error" />
        }[paymentStatus]
    }, [paymentStatus]);

    const getStatusIcon = useCallback(() => {
        let successIcon = <FontAwesomeIcon icon={["fas", "circle-check"]} className="text-success me-50"/>;
        let errorIcon = <FontAwesomeIcon icon={["fas", "circle-exclamation"]} className="text-danger me-50"/>;

        return paymentStatus === "success" ? successIcon : errorIcon
    }, [paymentStatus]);

    return <Card className="paymentResponse">
        <Card.Body>
            <div className="user-infos d-flex align-items-center">
                {user && <>
                    {
                        user?.picture && <Image src={user.picture} roundedCircle className="me-50" />
                    }
                    {
                        getDefaultNames({firstName: user?.firstName, lastName: user?.lastName}) && <span className="user-infos--name text-truncate me-1">
                            {getDefaultNames({firstName: user?.firstName, lastName: user?.lastName}, true).join(" ")}
                        </span>
                    }
                </>}
                {
                    // paymentStatus === "success" && 
                    <strong className="ms-auto">
                        -{paymentAmount}{currencySymbols[paymentCurrency]}
                    </strong>
                }
            </div>
            <ul className="list-unstyled">
                <li>
                    <label><FormattedMessage id="paymentResponse.label.status" defaultMessage="Status" /></label>
                    <div>
                        {getStatusIcon()}
                        {getStatus()}
                    </div>
                </li>
                {
                    paymentDate && paymentDate !== "" && <li>
                        <label><FormattedMessage id="paymentResponse.label.date" defaultMessage="Date" /></label>
                        {new Date(paymentDate).toLocaleString()}
                    </li>
                }
                {
                    paymentCard?.brand && paymentCard?.brand !== "" && <li>
                        <label><FormattedMessage id="paymentResponse.label.paymentMethod" defaultMessage="Payment method" /></label>
                        <div className="paymentResponse-creditCard">
                            {getCreditCardIconElt({className: "credit-creditCard--icon"})}
                            <div className="d-flex flex-column align-items-start w-100 ms-1">
                                <span className="paymentResponse-creditCard--brand">{paymentCard.brand}</span>
                                <span className="paymentResponse-creditCard--number">
                                    <FormattedMessage
                                        id="paymentResponse.creditCard.endingIn"
                                        defaultMessage="Ending in {last4}"
                                        values={{last4: paymentCard.last4}}
                                    />
                                </span>
                            </div>
                        </div>
                    </li>
                }
            </ul>

            <Button
                variant="light"
                onClick={() => dispatch(doPayment()).unwrap()}
            >
                <FormattedMessage id="paymentResponse.btn.sendAgain" defaultMessage="Send Again" />
            </Button>
        </Card.Body>
    </Card>
}

export default PaymentResponse;
