import { configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
// import paymentReducer from './paymentSlice'
import paymentReducer from '../features/payment/paymentSlice'

const DEBUG = window.location.hostname === "localhost" ? true : false;

const loggerMiddleware = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error
})

const middlewares = [...getDefaultMiddleware(), DEBUG && loggerMiddleware].filter(Boolean);
const store = configureStore({
    reducer: {
        payment: paymentReducer
    },
    middleware: [...middlewares]
})

export default store
