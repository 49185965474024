import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import PaymentPage from "./features/payment/PaymentPage";

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<PaymentPage />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter;