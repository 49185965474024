import React, { useCallback, useRef } from "react"
import {Form} from 'react-bootstrap';
import styled from 'styled-components';

const StyledAutoSizeInput = styled.div`
    position: relative;
    min-width: 1em;
    width: min-content;

    .autoSizeInput-sizeCalibration{
        visibility: hidden;
        white-space: pre;
        display: block;
    }

    .autoSizeInput-input{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border: 0;
        background-color: transparent;
    }

    .autoSizeInput-sizeCalibration, .autoSizeInput-input {
        max-width: 200px;
        height: 100%; 
    }
`

const AutoSizeInput = ({style = {}, ...rest}) => {
    const inputEl = useRef(null);
    const sizeEl = useRef(null);

    const onInput = useCallback(() => {
        if(inputEl.current && sizeEl.current){
            if(inputEl.current.value === ""){
                sizeEl.current.innerText = rest?.placeholder || ""
            } else {
                sizeEl.current.innerText = inputEl.current.value;
            }
        }
    }, [inputEl, sizeEl, rest]);

    return <StyledAutoSizeInput className='autoSizeInput-wrapper'>
        <span
            className='autoSizeInput-sizeCalibration'
            ref={sizeEl}
            style={style}
        >{rest?.placeholder || ""}</span>
        <Form.Control
            style={style}
            formNoValidate="formnovalidate"
            {...rest}
            ref={inputEl}
            onInput={onInput}
            className='autoSizeInput-input'
        />
    </StyledAutoSizeInput>
}

export default AutoSizeInput;