import Router from "./Router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";

import './App.scss';


library.add(fas, fal, fab, far, fad);

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
