import React from "react"
import {Card, Form, Button, Image, Spinner} from 'react-bootstrap';
import { FormattedMessage } from "react-intl";
import {Formik} from "formik";
import currencySymbols from "../../utilities/currencySymbols"
import AutoSizeInput from "../../components/form/AutoSizeInput"
import { getDefaultNames } from "../../utilities/utils"
import * as Yup from "yup"

import { doPayment } from "./paymentSlice"
import { useDispatch, useSelector } from "react-redux";

const formValidationSchema = Yup.object().shape({
	amount: Yup.number().required().positive()
});

const PaymentForm = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.payment.user);
    const amount = useSelector(state => state.payment.amount);
    const currency = useSelector(state => state.payment.currency);

    return <Card className="paymentView">
        <Card.Body>
            {
                user && <div className="mb-1 user-infos">
                    {
                        user?.picture && <>
                            <Image src={user.picture} roundedCircle className="mb-50" />
                            <br/>
                        </>
                    }
                    {
                        getDefaultNames({firstName: user?.firstName, lastName: user?.lastName}) && <span className="user-infos--name">
                            {getDefaultNames({firstName: user?.firstName, lastName: user?.lastName}).join(" ")}
                        </span>
                    }
                </div>
            }
            <Formik
                validationSchema={formValidationSchema}
                enableReinitialize
                initialValues={{ amount: amount/100 || "" }}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(true);

                    if(values.amount > 0){
                        dispatch(doPayment(values.amount)).unwrap();
                    }
                }}
            >
                {(form) => {
                    return <Form onSubmit={form.handleSubmit}>
                        <Form.Group controlId="amount" className="amount-formControl mb-4">
                            <div className='flat-input-wrapper'>
                                <AutoSizeInput
                                    placeholder="0.0"
                                    name="amount"
                                    size="lg"
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    value={form.values.amount}
                                    min="0"
                                    max="10000"
                                    style={{
                                        fontSize: "54px",
                                        fontWeight: "600"
                                    }}
                                    autoComplete={"off"}
                                />
                            <span className="ms-1">
                                {currencySymbols[currency]}
                            </span>
                            </div>
                        </Form.Group>

                        <Button
                            variant="primary"
                            type="submit"
                            disabled={(!form.isValid) || form.isSubmitting}
                        >
                            {form.isSubmitting && <Spinner animation="border" variant="white" size="sm" />}
                            {
                                !form.isSubmitting && <FormattedMessage id="paymentView.btn.switchToReecallPay" defaultMessage="Switch to Reecall Pay" />
                            }
                        </Button>
                    </Form>
                }}
            </Formik>
        </Card.Body>
    </Card>
}

export default PaymentForm;
