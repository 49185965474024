import visaLogo from "../assets/img/creditCardLogos/visa.png"
import amexLogo from "../assets/img/creditCardLogos/american-express.png"
import discoverLogo from "../assets/img/creditCardLogos/discover.png"
import jcbLogo from "../assets/img/creditCardLogos/jcb.png"
import mastercardLogo from "../assets/img/creditCardLogos/mastercard.png"
import maestroLogo from "../assets/img/creditCardLogos/maestro.png"
import dinersLogo from "../assets/img/creditCardLogos/diners-club.svg"
import unknownLogo from "../assets/img/creditCardLogos/card-logo-unknown.svg"

const mapCreditCardNameIcon = {
    "visa": visaLogo,
    "mastercard": mastercardLogo,
    "amex": amexLogo,
    "maestro": maestroLogo,
    "discover": discoverLogo,
    "optima": unknownLogo,
    "jcb": jcbLogo,
    "diners-club": dinersLogo,
    "enroute": unknownLogo,
}

export default mapCreditCardNameIcon

const getCreditCardIcon = (brand, {...rest}) => {
    if(!mapCreditCardNameIcon?.[brand.toLowerCase()]) return null
    return <img src={mapCreditCardNameIcon[brand.toLowerCase()]} alt={`Logo ${brand.toLowerCase()}`} {...rest} />
}

export { getCreditCardIcon }