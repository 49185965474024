import React, {useEffect} from "react"
import "../../assets/scss/views/paymentView.scss"
import Pusher from "pusher-js";
import { useSelector, useDispatch } from "react-redux";
import { setCallInfo, setPaymentResponse, setPaymentState } from "./paymentSlice"
import PaymentForm from "./PaymentForm";
import PaymentResponse from "./PaymentResponse";
import PaymentLoading from "./PaymentLoading"

const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER
});

const PaymentPage = () => {
    const dispatch = useDispatch();
    const paymentState = useSelector((state) => state.payment);
    const paymentStatus = useSelector((state) => state.payment.status);
    const pusherChannelName = useSelector((state) => state.payment.pusherChannelName);

    const urlParams     = new URLSearchParams(window.location.search);
    const callSid       = urlParams.get('callSid');
    const companyId     = urlParams.get('companyId');
    const userParams    = urlParams.get('user');
    const currency      = urlParams.get('currency');
    const amount      = urlParams.get('amount');

    useEffect(() => {
        dispatch(setCallInfo({
            callSid: callSid,
            companyId: companyId,
            currency: currency,
            amount: amount,
            user: userParams ? JSON.parse(userParams) : null,
        }))
    }, [dispatch, callSid, companyId, userParams, currency, amount]);

    useEffect(() => {
        if(pusherChannelName){
            const channel = pusher.subscribe(pusherChannelName);
            channel.bind("paymentCallback", (data) => {
                dispatch(setPaymentResponse(data));
            });
            channel.bind("paymentCallbackStatus", (data) => {
                dispatch(setPaymentState(data));
            });
        }
        return () => {
            if(pusherChannelName){
                pusher.unsubscribe(pusherChannelName);
            }
        };
    }, [pusherChannelName, dispatch]);

    if(paymentStatus === "loading") return <PaymentLoading />

    return paymentStatus !== "loading" && paymentStatus !== "idle" ? 
        <PaymentResponse /> 
    : 
        <PaymentForm/>
}

export default PaymentPage;