import React from "react"
import { Spinner } from "react-bootstrap";
import { FormattedMessage, defineMessages } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";

const statusMessages = defineMessages({
    "payment-card-number": {
        id: "paymentStatus.payment-card-number",
        defaultMessage: "The customer is asked for credit or debit card information"
    },
    "expiration-date": {
        id: "paymentStatus.expiration-date",
        defaultMessage: "The customer is asked for the expiration date for their payment card"
    },
    "security-code": {
        id: "paymentStatus.security-code",
        defaultMessage: "The customer is asked for the security code for their payment card"
    },
    "postal-code": {
        id: "paymentStatus.postal-code",
        defaultMessage: "The customer is asked for the postal code associated with the payment card"
    },
    "bank-routing-number": {
        id: "paymentStatus.bank-routing-number",
        defaultMessage: "The customer is asked for their bank's routing number"
    },
    "bank-account-number": {
        id: "paymentStatus.bank-account-number",
        defaultMessage: "The customer is asked for their bank account number"
    },
    "payment-processing": {
        id: "paymentStatus.payment-processing",
        defaultMessage: "The payment is processing"
    }
})

const StyledPaymentLoading = styled.div`
    .loading-text{
        padding: 0 1rem;
        font-weight: 500;
        font-size: 14px;
    }
`

const PaymentLoading = ({className = ""}) => {
    const paymentStatus = useSelector(state => state.payment.paymentStatus);

    return <StyledPaymentLoading 
        className={`d-flex flex-column align-items-center justify-content-center w-100 h-100 ${className}`}
    >
        <Spinner animation="border" variant="primary" className="mb-1" />
        <span className="loading-text text-center">
            {
                !paymentStatus && <FormattedMessage id="paymentLoading.paymentInProgress" defaultMessage="Payment in progress" />
            }
            {
                paymentStatus && <>
                    <FormattedMessage {...statusMessages[paymentStatus.status]}/>
                    <br/>
                    <small className="text-muted"><FormattedMessage id="paymentLoading.attempts" defaultMessage={`(attempt {attemptVal})`} values={{attemptVal: paymentStatus.currentAttempt}}/></small>
                </>
            }
        </span>
    </StyledPaymentLoading>
}

export default PaymentLoading;
