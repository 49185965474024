import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    callSid: null,
    companyId: null,
    pusherChannelName: null,
    currency: null,
    amount: null,
    date: null,
    card: null,
    status: 'idle',
    error: null,
    paymentStatus: null,
}

export const doPayment = createAsyncThunk('payment/doPayment', async (amount, { getState, rejectWithValue }) => {
    const state = getState();

    try {
        const response = await axios.post(`${process.env.REACT_APP_RC_RPA_BASE}/action`,{
            config:{
                integration:"twilio",
                action:"pay",
                type:"QaAction",
                datas:{
                    companyId: state.payment.companyId,
                    callSid: state.payment.callSid,
                    amount: (amount || state.payment.amount) * 100,
                    hook: process.env.REACT_APP_RC_TWILIO_PAYMENT_HOOK,
                    statusCallback: process.env.REACT_APP_RC_TWILIO_PAYMENT_STATUS_CALLBACK
                }
            }
        })

        return {
            amount: (amount || state.payment.amount)
        }
    } catch (err) {
        return rejectWithValue({
            amount: (amount || state.payment.amount)
        })
    }
})


const postsSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setCallInfo: (state, action) => {
            const {callSid, companyId, currency, user, amount} = action.payload;
            state.callSid = callSid;
            state.companyId = companyId;
            state.currency = currency;
            state.user = user;
            state.amount = amount;
            state.pusherChannelName = `RCWidget-${companyId}-${callSid}`;
        },
        setPaymentState: (state, action) => {
            state.paymentStatus = action.payload;
        },
        setPaymentResponse: (state, action) => {
            const {status, error, date, cardType, cardNumber, cardExpiration} = action.payload;
            state.status = status;
            state.error = error || null;
            state.date = date || null;
            state.card = {
                brand: cardType,
                last4: cardNumber.replace(/x/gi, ""),
                expiration: cardExpiration
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(doPayment.pending, (state, action) => {
                state.status = 'loading';
                state.date = null;
                state.card = null;
                state.error = null;
                state.paymentStatus = null;
            })
            .addCase(doPayment.fulfilled, (state, action) => {
                state.amount = action.payload.amount || state.amount;
            })
            .addCase(doPayment.rejected, (state, action) => {
                state.amount = action.payload.amount || state.amount;
            })
    }
})

export const { setCallInfo, setPaymentState, setPaymentResponse } = postsSlice.actions

export default postsSlice.reducer