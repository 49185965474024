import React from "react"
import { IntlProvider } from "react-intl"
import translations from "../assets/data/locales/translations.json"
import { IntlGlobalProvider } from "../components/lang/IntlGlobalProvider";

const INTL_DEBUG = false;
const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
  queryParams = new URLSearchParams(window.location.search);
  state = {
    locale: this.queryParams.get('lang') || localStorage.getItem('reecall-webapp-locale') || navigator.language.slice(0, 2) || 'en',
    messages: translations[this.queryParams.get('lang') || localStorage.getItem('reecall-webapp-locale') || navigator.language.slice(0, 2) || 'en']
  };
  
  render() {
      const { children } = this.props
      const { locale, messages } = this.state;

    return (
      <Context.Provider
        value={{state: this.state}}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
          onError={(message, detail) => {
            INTL_DEBUG && console.error(message);
          }}
        >
            <IntlGlobalProvider>
                {children}
            </IntlGlobalProvider>
        </IntlProvider>
      </Context.Provider>
    )
  }
}

export { IntlProviderWrapper, Context as IntlContext }
